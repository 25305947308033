import * as React from 'react'
import Button from '../../Components/Button/Button'
import Collapse from '../../Components/Collapse'
import {FindUserModalDTO} from '../../Components/FindUserModal'
import FormGroup from '../../Components/FormGroup'
import styled from 'styled-components'
import {SetState} from '../../Utilities/TypeHelpers'
import CheckboxControl from '../../Components/Fields/CheckboxControl'
import {components} from '../../Generated/eportal'
import TextField from '@mui/material/TextField'
import {toast} from 'react-toastify'
import Grid from '@mui/material/Grid'
import {DoctorGeneric, DoctorMan, DoctorWoman} from '../../Components/Icons/DoctorIcon'
import {DEFAULT_ICON_PROPS} from '../../Components/Icons/constants'
import SvgIcon from '@mui/material/SvgIcon'

const StyledCheckbox = styled(CheckboxControl)`
    width: 1.5em;
    height: 1.5em;
    input {
        position: inherit !important;
        margin-left: inherit !important;
        margin-right: 7px !important;
        width: 1.5em !important;
        height: 1.5em !important;
    }
`

interface StyledStyledFormGroup {
    bigscreen: string
}

const StyledFormGroup = styled(FormGroup)<StyledStyledFormGroup>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    label {
        font-weight: normal;
        margin-bottom: 0;
    }

    @media only screen and (max-width: 600px) {
        display: ${props => (props.bigscreen === 'true' ? 'block' : 'flex')};
        label {
            display: block;
            width: 100%;
        }

        input[type='text'] {
            display: block;
            width: 100%;
        }
    }
`

const doctorRoles = [
    '/api/user_roles/1',
    '/api/user_roles/2',
    '/api/user_roles/7',
    '/api/user_roles/8',
    '/api/user_roles/9',
    '/api/user_roles/10',
]

const validateEmail = email => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email)

type UserType =
    components['schemas']['User.jsonld-Stats.basic_Stats.offices_Office.basic_Office.users_User.basic_User.censoredEmail_User.email-validation_User.userRole_UserRole.basic_Stats.partnership_Partnership.basic_Partnership.details_Partnership.seminars_Partnership.members_Member.basic']
type EmployeeOffice = any

export type ParticipantsType = {users: number[]; extraUsers: FindUserModalDTO[]; init: boolean}
type ParticipantsProps = {
    participants: ParticipantsType
    setParticipants: SetState<ParticipantsType>
    toggleUser: (user: UserType, checked: boolean) => void
    toggleOffice: (office: EmployeeOffice, checked: boolean) => void
    selected: number
    expanded: number[]
    setExpanded: SetState<number[]>
    o: EmployeeOffice
    userRoles: {'@id': string; name: string; '@type': string; id: number}[]
    bigScreen?: boolean
    xs?: number
}

export default function OfficeComponent({
    participants,
    setParticipants,
    o,
    toggleUser,
    toggleOffice,
    selected,
    expanded,
    setExpanded,
    userRoles,
    bigScreen,
    xs = 12,
}: ParticipantsProps) {
    const isExpanded = expanded.includes(o.id)
    function handleInputClick(event) {
        event.stopPropagation() // Prevents the div's onClick from firing
    }
    return (
        <div
            key={o.id}
            style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '0.5em',
                paddingTop: '0em',
                marginBottom: '1em',
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                backgroundColor: '#f7f7f7a8',
            }}
        >
            <div style={{marginBottom: isExpanded ? '2em' : '1em', marginTop: '0em'}}>
                <Grid container xs={12} spacing={1} mt={0} mb={0} sx={{justifyContent: 'end', alignItems: 'center'}}>
                    <Grid item xs={12} mt={0} mb={0} sx={{justifyContent: 'end', alignItems: 'center'}}>
                        {o.isRegistered ? (
                            <div style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: 0, width: '100%'}}>
                                <div style={{marginLeft: '0', marginTop: '0'}}>
                                    <em className='label label-primary pull-right'>SKIL</em>
                                </div>
                            </div>
                        ) : (
                            <div style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: 0, width: '100%'}}>
                                <div style={{marginLeft: '0', marginTop: '0'}}>
                                    <em className='label label-info pull-right'>HELFO</em>
                                </div>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} mt={0} mb={0} sx={{justifyContent: 'end', alignItems: 'center'}}>
                        <Button
                            onClick={() =>
                                setExpanded(
                                    prev =>
                                        prev.includes(o.id)
                                            ? prev.filter(id => id !== o.id) // Remove if it's already there
                                            : [...prev, o.id] // Add if it's not
                                )
                            }
                            inline
                            variant={'link'}
                            style={{fontSize: 'initial'}}
                        >
                            {o.name} <i className={'fa fa-caret-down'} />
                        </Button>
                        <small>
                            {' '}
                            {selected} av {o.employees.length} valgt{' '}
                        </small>
                        <span className={'pull-right'}>
                            <Button onClick={() => toggleOffice(o, true)} inline variant={'link'}>
                                Velg alle
                            </Button>
                            <Button onClick={() => toggleOffice(o, false)} inline variant={'link'}>
                                Velg ingen
                            </Button>
                        </span>
                    </Grid>
                </Grid>
            </div>
            {isExpanded && (
                <Collapse expanded={isExpanded}>
                    {o.employees.map(d => {
                        const checked = participants.users.includes(d['@id']) || participants.extraUsers.some(u => u.id === d.id)
                        return (
                            <div
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '0em',
                                    marginBottom: '0.5em',
                                    boxShadow:
                                        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                                    backgroundColor: '#fff',
                                }}
                                onClick={() => {
                                    if (d['@id'].includes('/api/users/')) {
                                        toggleUser(d, !checked)
                                    }
                                    if (d['@id'].includes('/api/helfo_doctors/')) {
                                        setParticipants(participants => {
                                            // Check if the user already exists in the extraUsers list
                                            const existingUserIndex = participants.extraUsers.findIndex(u => u.id === d.id)
                                            if (existingUserIndex === -1 && !checked) {
                                                // Add the user if they do not exist
                                                participants.extraUsers.push({
                                                    id: d.id,
                                                    email: '',
                                                    role: d.isTemp ? '/api/user_roles/10' : '/api/user_roles/1',
                                                    name: d.name,
                                                    type: 'helfo',
                                                    office: {
                                                        officeType: 'external',
                                                        externalOffice: o['@id'].includes('/api/external_offices/')
                                                            ? o['@id']
                                                            : o.helfoOffice,
                                                        officeName: o.name,
                                                    },
                                                })
                                            } else {
                                                if (checked) {
                                                    // If the checkbox is unchecked and user exists, remove the user
                                                    participants.extraUsers.splice(existingUserIndex, 1)
                                                }
                                            }

                                            return {...participants}
                                        })
                                    }
                                }}
                            >
                                <Grid
                                    container
                                    xs={12}
                                    spacing={1}
                                    mt={0}
                                    mb={2}
                                    sx={{justifyContent: 'space-between', alignItems: 'center'}}
                                >
                                    {!d.isRegistered && (
                                        <Grid item xs={12} sx={{justifyContent: 'space-between', alignItems: 'center', paddingTop: 0}}>
                                            <div style={{marginLeft: '0', marginTop: '0'}}>
                                                <em className='label label-info pull-right'>HELFO</em>
                                            </div>
                                        </Grid>
                                    )}
                                    {d.isRegistered && (
                                        <Grid item xs={12} sx={{justifyContent: 'space-between', alignItems: 'center', paddingTop: 0}}>
                                            <div style={{marginLeft: '0', marginTop: '0'}}>
                                                <em className='label label-primary pull-right'>SKIL</em>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sx={{display: 'flex', padding: '0 !important'}}>
                                        <Grid item xs={'auto'} sx={{maxWidth: 'fit-content'}}>
                                            <div style={{display: 'flex', maxWidth: 'fit-content', marginLeft: '1.5em'}}>
                                                <StyledCheckbox
                                                    checked={checked}
                                                    disabled={false}
                                                    onChange={e => {
                                                        if (d['@id'].includes('/api/users/')) {
                                                            toggleUser(d, e.target.checked)
                                                        }
                                                        if (d['@id'].includes('/api/helfo_doctors/')) {
                                                            setParticipants(participants => {
                                                                // Check if the user already exists in the extraUsers list
                                                                const existingUserIndex = participants.extraUsers.findIndex(
                                                                    u => u.id === d.id
                                                                )
                                                                if (existingUserIndex === -1 && e.target.checked) {
                                                                    // Add the user if they do not exist
                                                                    participants.extraUsers.push({
                                                                        id: d.id,
                                                                        email: '',
                                                                        role: d.isTemp ? '/api/user_roles/10' : '/api/user_roles/1',
                                                                        name: d.name,
                                                                        type: 'helfo',
                                                                        office: {
                                                                            officeType: 'external',
                                                                            externalOffice: o['@id'].includes('/api/external_offices/')
                                                                                ? o['@id']
                                                                                : o.helfoOffice,
                                                                            officeName: o.name,
                                                                        },
                                                                    })
                                                                } else {
                                                                    if (!e.target.checked && existingUserIndex !== -1) {
                                                                        // If the checkbox is unchecked and user exists, remove the user
                                                                        participants.extraUsers.splice(existingUserIndex, 1)
                                                                    }
                                                                }

                                                                return {...participants}
                                                            })
                                                        }
                                                    }}
                                                />
                                                {d.helfoRole !== null || doctorRoles.includes(d.skilRole) ? (
                                                    <>
                                                        {d.isMale === true && (
                                                            <SvgIcon {...DEFAULT_ICON_PROPS} sx={{height: '1.7em', width: '1.7em'}}>
                                                                <DoctorMan />
                                                            </SvgIcon>
                                                        )}

                                                        {d.isMale === false && (
                                                            <SvgIcon {...DEFAULT_ICON_PROPS} sx={{height: '1.7em', width: '1.7em'}}>
                                                                <DoctorWoman />
                                                            </SvgIcon>
                                                        )}

                                                        {d.isMale === null && (
                                                            <SvgIcon viewBox={'-125 350 800 800'} sx={{height: '1.7em', width: '1.7em'}}>
                                                                <DoctorGeneric />
                                                            </SvgIcon>
                                                        )}
                                                    </>
                                                ) : (
                                                    <i title={''} style={{width: '1.1em'}} className={'fa fa-2x fa-fw fa-question'} />
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={10} sx={{display: 'flex', alignItems: 'center', marginLeft: '0.5em'}}>
                                            <div>
                                                <span style={{maxWidth: 'fit-content'}}>{d.name}</span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {d.isRegistered && checked && (
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: checked ? 'space-between' : 'right',
                                                    width: '100%',
                                                }}
                                            >
                                                <TextField
                                                    id={`${d.id}-textField-email`}
                                                    required={false}
                                                    placeholder='e-postadresse'
                                                    value={d.email}
                                                    disabled={true}
                                                    onClick={e => handleInputClick(e)}
                                                    style={{
                                                        margin: '0em',
                                                        marginLeft: '0.5em',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'left',
                                                        width: bigScreen ? '50%' : '100%',
                                                    }}
                                                    sx={{
                                                        '& .MuiFormControl-root': {margin: 0},
                                                        '& div': {margin: 0},
                                                        '& div.MuiFormControl-root': {margin: 0},
                                                        '& .MuiInputBase-input': {
                                                            padding: '0.7em',
                                                            fontSize: 'small',
                                                            height: '1.25em',
                                                            textAlign: 'center',
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    )}
                                    {!d.isRegistered && checked && (
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: checked ? 'space-between' : 'right',
                                                    width: '100%',
                                                }}
                                            >
                                                <TextField
                                                    id={`${d.id}-textField`}
                                                    required={false}
                                                    placeholder='e-postadresse'
                                                    value={participants.extraUsers.find(u => u.id === d.id)?.email}
                                                    onClick={e => handleInputClick(e)}
                                                    onChange={e => {
                                                        setParticipants(participants => {
                                                            // Find the index of the user with the matching id
                                                            const userIndex = participants.extraUsers.findIndex(u => u.id === d.id)

                                                            if (userIndex !== -1) {
                                                                // Update the email of the existing user
                                                                participants.extraUsers[userIndex].email = e.target.value
                                                            } else {
                                                                toast('Deltaker er fjernet', {type: 'error'})
                                                            }

                                                            // Return the updated participants state
                                                            return {...participants}
                                                        })
                                                    }}
                                                    style={{
                                                        margin: '0em',
                                                        marginLeft: '1em',
                                                        display: 'flex',
                                                        justifyContent: 'right',
                                                        alignItems: 'left',
                                                        width: bigScreen ? '50%' : '100%',
                                                    }}
                                                    sx={{
                                                        '& .MuiFormControl-root': {margin: 0},
                                                        '& div': {margin: 0},
                                                        '& div.MuiFormControl-root': {margin: 0},
                                                        '& .MuiInputBase-input': {
                                                            padding: '0.7em',
                                                            fontSize: 'small',
                                                            height: '1.25em',
                                                        },
                                                    }}
                                                    error={(() => {
                                                        const helfoUser = participants.extraUsers.find(u => u.id === d.id)
                                                        if (helfoUser && !validateEmail(helfoUser.email)) {
                                                            return true
                                                        }
                                                        return helfoUser?.email === '' || helfoUser?.email === undefined
                                                    })()}
                                                />
                                            </div>
                                        </Grid>
                                    )}
                                    {!d.validEmail && d.isRegistered && (
                                        <Grid item xs={12}>
                                            <FormGroup
                                                key={`${d.id}-formgroup-validEmail`}
                                                bigscreen={bigScreen?.toString()}
                                                style={{marginLeft: '1em'}}
                                            >
                                                <i
                                                    title='Ugyldig e-postadresse. Brukeren vil mest sannsynlig ikke motta e-poster og invitasjoner.'
                                                    className='fa fa-exclamation-triangle'
                                                    aria-hidden='false'
                                                >
                                                    Ugyldig e-postadresse
                                                </i>
                                            </FormGroup>
                                        </Grid>
                                    )}
                                    {d.tempFor && (
                                        <Grid item xs={12}>
                                            <FormGroup
                                                key={`${d.id}-formgroup-tempFor`}
                                                bigscreen={bigScreen?.toString()}
                                                style={{marginLeft: '1em'}}
                                            >
                                                <i
                                                    title={''}
                                                    style={{width: '1em', marginRight: '0.25em'}}
                                                    className={'fa fa-info-circle'}
                                                />
                                                <small>{d.tempFor}</small>
                                            </FormGroup>
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        )
                    })}
                </Collapse>
            )}
        </div>
    )
}
